import React, { useContext } from 'react'
import { TimeContext } from '../context/TimeContext'
import Button from '@atlaskit/button'
import { sameDays } from '../utils/date'

export default function WeekCalendar() {
  const today = new Date()
  const { time, handleDayChange } = useContext(TimeContext)

  let [tomorrow, dayAfterTomorrow] = [
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2),
  ]

  function calendarDayButton(day) {
    return sameDays(day, time) ? (
      <Button
        isDisabled={true}
        className="weekDay"
        onClick={() => handleDayChange(today, day)}
      >
        {day.toLocaleString('En', { month: 'short', day: 'numeric' })}
      </Button>
    ) : (
      <Button
        isDisabled={false}
        className="weekDay"
        onClick={() => handleDayChange(today, day)}
      >
        {day.toLocaleString('En', { day: 'numeric' })}
      </Button>
    )
  }

  return (
    <div id="weekCalendar">
      {calendarDayButton(today)}
      {calendarDayButton(tomorrow)}
      {calendarDayButton(dayAfterTomorrow)}
    </div>
  )
}
