import React, { useContext } from 'react'
import { TimeContext } from '../context/TimeContext'
import { HighlighterContext } from '../context/HighlighterContext'

export default function Hour({ hour, hourPosition }) {
  const { time } = useContext(TimeContext)
  const { handleMouseEnter } = useContext(HighlighterContext)
  var tomorrow = new Date()
  tomorrow.setDate(time.getDate() + 1)
  var yesterday = new Date()
  yesterday.setDate(time.getDate() - 1)

  return (
    <div
      className={`dayCell
                  ${
                    hour % 24 === 0 || hourPosition === 0
                      ? 'calendarCell'
                      : 'hourCell'
                  } 
                  ${
                    [1, 2, 3, 4, 5, 22, 23].includes(hour % 24)
                      ? 'lateEarlyHour'
                      : ''
                  }
                  ${
                    [6, 7, 18, 19, 20, 21].includes(hour % 24)
                      ? 'nonWorkHour'
                      : ''
                  }
                  ${[23].includes(hour % 24) ? 'endOfDay' : ''}
                `}
      onMouseEnter={() => handleMouseEnter(hourPosition)}
    >
      {hour % 24 === 0 || hourPosition === 0 ? (
        <div>
          <div>
            {hour < 24
              ? yesterday.toLocaleString('en-us', { month: 'short' })
              : hour < 48
              ? time.toLocaleString('en-us', { month: 'short' })
              : tomorrow.toLocaleString('en-us', { month: 'short' })}
          </div>
          <h3>
            {hour < 24
              ? yesterday.toLocaleString('en-us', { month: 'numeric' })
              : hour < 48
              ? time.toLocaleString('en-us', { day: 'numeric' })
              : tomorrow.toLocaleString('en-us', { day: 'numeric' })}
          </h3>
        </div>
      ) : (
        <div>
          <h3>{hour % 12 || 12}</h3>
          <div>{hour % 24 >= 12 ? 'pm' : 'am'}</div>
        </div>
      )}
    </div>
  )
}
