import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ProvideTimeContext } from './context/TimeContext'
import { ProvideHighlighterContext } from './context/HighlighterContext'
import { ProvideTimezoneOptionsContext } from './context/TimezoneOptionsContext'

ReactDOM.render(
  <ProvideTimezoneOptionsContext>
    <ProvideTimeContext>
      <ProvideHighlighterContext>
        <App />
      </ProvideHighlighterContext>
    </ProvideTimeContext>
  </ProvideTimezoneOptionsContext>,
  document.getElementById('root')
)
