import React, { useContext, useEffect } from 'react'
import { TimeContext } from '../context/TimeContext'
import { HighlighterContext } from '../context/HighlighterContext'
//import { Analytics } from '../utils/analytics'
import Timezone from './Timezone'
import WeekCalendar from './WeekCalendar'
import Button from '@atlaskit/button'
import HourHighlighter from './HourHighlighter'

export default function Timezones({ showTimeZones }) {
  const { timezones, addTimezone } = useContext(TimeContext)
  const { handleMouseOut } = useContext(HighlighterContext)
  const timezonesList =
    timezones &&
    timezones.length > 0 &&
    timezones.map((t, index) => <Timezone key={index} id={index} />)

  // Track timezones component only if it's non-zero timezones are loaded
  useEffect(() => {
    if (showTimeZones === true) {
    }
    //Analytics.PageViewed({ showTimeZones, timezones })
  }, [showTimeZones])

  return (
    <div
      id="timezones"
      onMouseLeave={() => handleMouseOut()}
      style={{
        opacity: showTimeZones === true ? '1' : '0',
        visibility: showTimeZones === true ? 'visible' : 'hidden',
      }}
    >
      {timezones.length > 0 && <WeekCalendar />}
      {timezones.length > 0 && <HourHighlighter />}
      {timezonesList}
      {timezones.length >= 0 && timezones.length <= 3 && (
        <Button id="addTimezoneButton" onClick={addTimezone}>
          + Add timezone
        </Button>
      )}
    </div>
  )
}
