import React, { useContext } from 'react'
import { TimeContext } from '../context/TimeContext'
import { HighlighterContext } from '../context/HighlighterContext'

export default function CurrentTimeHighlighter() {
  const { timezones } = useContext(TimeContext)
  const { leftMargin } = useContext(HighlighterContext)
  const hourHeight = window.innerWidth > 1200 ? 110 : 98
  const marginTop = 32 + timezones.length * 0
  const height = timezones.length * hourHeight - 42

  return (
    <div
      id="highlighterBorder"
      style={{
        marginLeft: leftMargin,
        height: height,
        marginTop: marginTop,
      }}
    ></div>
  )
}
