import React, { useState, useEffect } from 'react'

export default function Clock({ showTimeZones, handleShowTimeZones }) {
  const [clockTime, setClockTime] = useState(new Date())
  const date = `${clockTime.toLocaleString('En', {
    weekday: 'long',
  })} • ${clockTime.toLocaleString('en-us', {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  })}`

  useEffect(() => {
    function getTime() {
      let interval = (60 - new Date().getSeconds()) * 1000 + 5
      setClockTime(new Date())
      setTimeout(getTime, interval)
    }
    getTime()
  }, [])

  return (
    <div id="dateTimeHeader">
      <div id="dateHeading">{date}</div>
      {showTimeZones ? (
        <div id="hourHeading" onClick={() => handleShowTimeZones()}>
          {clockTime.getHours() % 12 || 12}:
          {('0' + clockTime.getMinutes()).slice(-2)}
          <span id="timeofDay">
            {' '}
            {clockTime.getHours() >= 12 ? 'PM' : 'AM'}
          </span>
        </div>
      ) : (
        <div id="logoHeading" onClick={() => handleShowTimeZones()}>
          Google
        </div>
      )}
    </div>
  )
}
