import React, { useEffect } from 'react'
//import { Analytics } from '../utils/analytics'

export default function GoogleSearch({ showTimeZones }) {
  useEffect(() => {
    //if (showTimeZones === false) Analytics.PageViewed({ showTimeZones })
  }, [showTimeZones])

  return (
    <div
      id="googleSearchWrapper"
      style={{
        opacity: showTimeZones === false ? '1' : '0',
        visibility: showTimeZones === false ? 'visible' : 'hidden',
      }}
    >
      <div className="inputIcon">
        <SearchIcon />
      </div>
      <form action="https://www.google.com/search" method="get">
        <input
          id="input"
          name="q"
          type="search"
          //autocomplete="off"
          //spellcheck="false"
          role="combobox"
          aria-controls="matches"
          placeholder="Search Google or type a URL"
          aria-expanded="false"
          aria-live="polite"
        />
      </form>
    </div>
  )
}

const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
)

const SearchIcon = () => (
  <Svg style={{ marginRight: '12px', width: '48px', height: '28px' }}>
    <path
      d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)
