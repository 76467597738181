import React, { useContext } from 'react'
import { TimeContext } from '../context/TimeContext'
import { tzCity } from '../utils/date'

export default function MiniTimezones({ hideTimeZones, handleShowTimeZones }) {
  const { time, timezones } = useContext(TimeContext)
  const timezonesList =
    timezones &&
    timezones.length > 0 &&
    timezones.map((t, index) => {
      return (
        <div
          key={index}
          id={index}
          className="miniTimezone"
          onClick={() => handleShowTimeZones()}
        >
          <div className="miniTimezoneFlag">
            <img
              src={`https://flagcdn.com/16x12/${tzCity(
                t.city
              ).toLowerCase()}.png`}
              alt="US"
            />
          </div>
          <div className="miniTimezoneCity">{t.city}</div>
          <div className="miniTimezoneTime">
            {time
              .toLocaleString('en-us', {
                timeZone: t.tz,
                hour: '2-digit',
                minute: '2-digit',
              })
              .slice(0, -2)}
            <span>
              {time
                .toLocaleString('en-us', {
                  timeZone: t.tz,
                  hour: '2-digit',
                  minute: '2-digit',
                })
                .slice(-2)}
            </span>
          </div>
        </div>
      )
    })

  return (
    <div
      id="miniTimezones"
      style={{
        opacity: hideTimeZones === true ? '1' : '0',
        height: hideTimeZones === true ? 'inherit' : '0',
        visibility: hideTimeZones === true ? 'visible' : 'hidden',
      }}
    >
      {timezonesList}
    </div>
  )
}
