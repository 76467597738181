import React, { useContext } from 'react'
import Hour from './DayHour'
import TimezoneSelect from './TimezoneSelect/index'
import { TimeContext } from '../context/TimeContext'
import { tzUtcOffset, tzCity } from '../utils/date'
import Button from '@atlaskit/button'
import { TimezoneOptionsContext } from '../context/TimezoneOptionsContext'

export function Timezone({ id }) {
  const { time, timezones, homeTz, handleTimezoneChange, removeTimezone } =
    useContext(TimeContext)
  const { tzOptions } = useContext(TimezoneOptionsContext)
  const hoursOfDay = [...Array(72).keys()]
  const hourDifference =
    -(tzUtcOffset(homeTz.tz) - tzUtcOffset(timezones[id].tz)) / 60

  const dayHours = hoursOfDay.splice(hourDifference + 24, 24)
  const hoursArr = dayHours.map((h, index) => (
    <Hour key={h} hour={h} hourPosition={index} />
  ))

  function LocationInfo() {
    const weekday = time.toLocaleString('En', { weekday: 'long' })
    const dateString = time.toLocaleString('en-us', {
      timeZone: timezones[id].tz,
      month: 'long',
      year: '2-digit',
      day: 'numeric',
    })
    const timeString = time.toLocaleString('en-us', {
      timeZone: timezones[id].tz,
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    })

    return (
      <span className="timezoneTimeInfo">
        • <span className="dateInfo">{weekday}</span> {timeString}
      </span>
    )
  }

  function TimeZoneHeader() {
    return (
      <h4>
        <TimezoneSelect
          tzOptions={tzOptions}
          internalTimezone={timezones[id]}
          internalCountryCode={tzCity(timezones[id].city)}
          handleTimezoneChange={(event) => handleTimezoneChange(id, event)}
        />
        <LocationInfo />
        <Button
          apperance="subtle"
          className="trashIcon"
          iconBefore={<Trash />}
          onClick={() => removeTimezone(id)}
        />
      </h4>
    )
  }

  return (
    <div className="timezone">
      <TimeZoneHeader />
      <div className="calendarDay">{hoursArr}</div>
    </div>
  )
}

export default React.memo(Timezone)

const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
)

const Trash = () => (
  <Svg style={{ marginRight: -6, width: '18px' }}>
    <path
      d="M10 10V16M14 10V16M18 6V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6M4 6H20M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
    />
  </Svg>
)
