import React, { useEffect, useState, createContext } from 'react'
import { sameDays } from '../utils/date'
import moment from 'moment-timezone'
//import { Analytics } from '../utils/analytics'

const TimeContext = createContext()

function ProvideTimeContext({ children }) {
  // Bug, home city is not picked
  let localCity = Intl.DateTimeFormat().resolvedOptions().timeZone
  localCity = localCity.split('/')[1].replace('_', ' ')
  const homeTz = { city: localCity, tz: moment.tz.guess() }

  const [time, setTime] = useState(new Date())
  const [timezones, setTimezones] = useState([])
  const [timeTravel, setTimeTravel] = useState(false)
  const [showTimeZones, setShowTimeZones] = useState(null)

  useEffect(() => {
    let defaultTimezones = [
      homeTz,
      { city: 'New York', tz: 'America/New_York' },
      { city: 'Vienna', tz: 'Europe/Vienna' },
    ]

    setTimezones(
      JSON.parse(localStorage.getItem('timezones') || false) || defaultTimezones
    )
  }, []) // Todo Something weird happens here, when homeTz is a dependency

  useEffect(() => {
    localStorage.setItem('timezones', JSON.stringify(timezones))
  }, [timezones])

  useEffect(() => {
    setShowTimeZones(JSON.parse(localStorage.getItem('showTimeZones') || true))
  }, [])

  useEffect(() => {
    localStorage.setItem('showTimeZones', JSON.stringify(showTimeZones))
  }, [showTimeZones])

  useEffect(() => {
    function getTime(timeTravel) {
      if (timeTravel === false) {
        let interval = (60 - new Date().getSeconds()) * 1000 + 5
        setTime(new Date())
        setTimeout(getTime, interval)
      }
    }
    getTime(timeTravel)
  }, [timeTravel])

  function handleTimezoneChange(id, newTimezone) {
    setTimezones((prev) => {
      //Analytics.UpdateTimezone({ id, newTimezone, timezones })

      let newTimezones = [...prev]
      newTimezones[id] = newTimezone
      return newTimezones
    })
  }

  function handleShowTimeZones() {
    setShowTimeZones((prev) => {
      //Analytics.ChangePageType({ showTimeZone: prev })
      return !prev
    })
  }

  function addTimezone(event) {
    event.preventDefault()
    setTimezones((prev) => {
      let tz = moment.tz.guess()
      //Analytics.AddTimezone({ prevTimezones: prev, city: localCity, tz })

      return [...prev, { city: localCity, tz }]
    })
  }

  function removeTimezone(id) {
    setTimezones((prev) => {
      //Analytics.DeleteTimezone({ id, prevTimezones: prev })

      let newTimezones = [...prev]
      newTimezones.splice(id, 1)
      return newTimezones
    })
  }

  // Can be based on relative # of days, or specific day
  function handleDayChange(todayDate, newDate) {
    setTime((prev) => {
      //Analytics.UpdateDate({ newDate, todayDate })
      return newDate
    })
    if (sameDays(newDate, todayDate)) {
      setTimeTravel(false)
    } else {
      setTimeTravel(true)
    }
  }

  return (
    <TimeContext.Provider
      value={{
        time,
        timeTravel,
        timezones,
        showTimeZones,
        handleShowTimeZones,
        homeTz,
        handleTimezoneChange,
        addTimezone,
        removeTimezone,
        handleDayChange,
      }}
    >
      {children}
    </TimeContext.Provider>
  )
}

export { TimeContext, ProvideTimeContext }
