import React from 'react'
import logo from '../images/Logo.png'
import Toggle from 'react-toggle'
import MiniTimezones from './MiniTimezones'

export default function Footer({ showTimeZones, handleShowTimeZones }) {
  return (
    <section id="footer">
      <MiniTimezones
        hideTimeZones={!showTimeZones}
        handleShowTimeZones={handleShowTimeZones}
      />
      <div id="footerToggle">
        <button onClick={() => handleShowTimeZones()}> Mini</button>
        <Toggle
          icons={{
            checked: null,
            unchecked: null,
          }}
          defaultChecked={!showTimeZones}
          onChange={() => handleShowTimeZones()}
        />{' '}
        <button onClick={() => handleShowTimeZones()}> Full</button>
      </div>
      <div id="footerLogo">
        <img
          src={logo}
          onClick={() => handleShowTimeZones()}
          alt="Time Genie logo"
          id="timeGenieLogo"
        ></img>
      </div>
    </section>
  )
}
