import React, { createContext, useState } from 'react'

const HighlighterContext = createContext()

function ProvideHighlighterContext({ children }) {
  const time = new Date()
  const [leftMargin, setLeftMargin] = useState(time.getHours() * 40 - 1)

  const handleMouseEnter = (hourPosition) => {
    setLeftMargin(hourPosition * 40 - 1)
  }

  const handleMouseOut = () => {
    setLeftMargin(time.getHours() * 40 - 1)
  }

  return (
    <HighlighterContext.Provider
      value={{
        leftMargin,
        handleMouseEnter,
        handleMouseOut,
      }}
    >
      {children}
    </HighlighterContext.Provider>
  )
}

export { HighlighterContext, ProvideHighlighterContext }
