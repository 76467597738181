import React, { useEffect, useContext } from 'react'
import Clock from './components/Clock'
import TimeZones from './components/Timezones'
import { TimeContext } from './context/TimeContext'
import Footer from './components/Footer'
import './App.css'
import GoogleSearch from './components/GoogleSearch'

function App() {
  const { showTimeZones, handleShowTimeZones } = useContext(TimeContext)

  useEffect(() => {
    fetch('https://picsum.photos/1024/600')
      .then((resp) => resp)
      .then((image) => {
        let main = document.getElementsByTagName('main')[0]
        main.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("${image.url}")`
        main.style.backgroundSize = 'cover'
      })
      .catch(() => {
        let main = document.getElementsByTagName('main')[0]
        main.style.backgroundColor = 'grey'
      })
  }, [])

  useEffect(() => {
    document.title = 'Time Genie'
    document.description =
      "Time Genie is a powerful Chrome extension that helps users stay on top of their productivity game. It offers a variety of features that make it easy to manage time zones, schedule meetings, and plan travel. With its carefully thought-out design, Time Genie lets users effortlessly compare multiple time zones at a glance, plan conference calls, and track market hours. Whether you're a busy professional or simply someone who likes to stay connected with friends and family abroad, Time Genie is the perfect tool to help you manage your time and stay productive."
  }, [])

  return (
    <div className="App">
      <main>
        <Clock
          showTimeZones={showTimeZones}
          handleShowTimeZones={handleShowTimeZones}
        />
        <GoogleSearch showTimeZones={showTimeZones} />
        <TimeZones showTimeZones={showTimeZones} />
        <Footer
          showTimeZones={showTimeZones}
          handleShowTimeZones={handleShowTimeZones}
        ></Footer>
      </main>
    </div>
  )
}

export default App
