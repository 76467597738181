import moment from 'moment-timezone'
const cityTimezones = require('city-timezones')

// Returns 2-letter uppercase country code for a city string. If multiple cities are provided app choses the largest one by population
function tzCity(city) {
  const maxPopCity = cityTimezones
    .lookupViaCity(city)
    .reduce((prev, current) => {
      return prev.pop > current.pop ? prev : current
    })

  return maxPopCity['iso2']
}

//
function tzUtcOffset(tz) {
  return moment().tz(tz).utcOffset()
}

// Common date functions
function sameDays(time1, time2) {
  return (
    removeTimeFromDate(time1).getTime() === removeTimeFromDate(time2).getTime()
  )
}

function removeTimeFromDate(date) {
  var newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

export { sameDays, tzCity, tzUtcOffset }
