import React, { useState } from 'react'
import Select, { createFilter } from 'react-select'
import Button from '@atlaskit/button'
import CustomMenuList from './CustomMenuList'
import CustomOption from './CustomOption'

export default function TimezoneSelect({
  tzOptions,
  internalTimezone,
  internalCountryCode = 'US',
  handleTimezoneChange,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState({
    label: internalTimezone.city,
    value: { offset: internalTimezone, countryCode: internalCountryCode },
  })
  const flagSrc = `https://flagcdn.com/16x12/${internalCountryCode.toLowerCase()}.png`

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          iconAfter={<ChevronDown />}
          iconBefore={<img src={flagSrc} alt="US" />}
          onClick={() => setIsOpen((prev) => !prev)}
          isSelected={isOpen}
          className="timezoneButton"
          spacing="subtle-link"
        >
          {value ? value.label : 'Select a location / timezone'}
        </Button>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator,
          Option: CustomOption,
          MenuList: CustomMenuList,
          IndicatorSeparator: null,
        }}
        controlShouldRenderValue={false}
        filterOption={createFilter({
          ignoreAccents: false,
          matchFrom: 'any',
          stringify: (option) => `${option.data.labelText}`,
        })}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={(e) => {
          setValue(e.value)
          handleTimezoneChange(e.value)
          setIsOpen(false)
        }}
        options={tzOptions}
        //optionRenderer={optionRenderer}
        placeholder="Search..."
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
      />
    </Dropdown>
  )
}

// styled components
const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: 8,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 1,
        fontSize: '14px',
        position: 'absolute',
        padding: '8px 8px 2px 8px',
        minWidth: '550px',
        zIndex: 2,
      }}
      {...props}
    />
  )
}

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
)

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
)

const DropdownIndicator = () => (
  <div style={{ color: 'rgba(200,200,200,0.8)', height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
)

const ChevronDown = () => (
  <Svg style={{ marginRight: -6, width: '18px' }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)

const selectStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: 'rgba(255,255,255,0.9)',
  }),
  option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...baseStyles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? 'rgba(0,0,0,0.5)'
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'rgba(0,0,0,0.9)'
        : 'rgba(0,0,0,0.7)',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...baseStyles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : 'rgba(0,0,0,0.3)'
          : undefined,
      },
    }
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
}
