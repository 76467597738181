import React, { createContext, useState, useEffect } from 'react'
import moment from 'moment-timezone'
const cityTimezones = require('city-timezones')
const TimezoneOptionsContext = createContext()

function ProvideTimezoneOptionsContext({ children }) {
  const [tzOptions, setTzOptions] = useState([])

  useEffect(() => {
    let options = []
    cityTimezones.cityMapping.forEach((value) => {
      if (
        !['25 de Mayo', '28 de Noviembre', "'Ataq", "'s-Hertogenbosch"].some(
          (el) => value['city'].includes(el)
        ) &&
        value['pop'] >= 100000
      ) {
        let mmntTz = moment.tz(value['timezone'])
        let offset = mmntTz.utcOffset() / 60
        let labelInfo = `${
          mmntTz.zoneAbbr().includes('+') || mmntTz.zoneAbbr().includes('-')
            ? ''
            : `${mmntTz.zoneAbbr()}, `
        }${offset > 0 ? `+${offset}` : offset}`
        let labelCity = `${
          value['country'] === 'United States of America'
            ? 'United States'
            : value['country']
        }${value['province'] ? `, ${value['province']}` : ''} ${
          value['city']
        }, (${labelInfo})`
        options.push({
          label: (
            <div className="optionRow">
              <span className="optionCityName">{labelCity}</span>
              <span className="optionTimeInfo">{mmntTz.format('hh:mm A')}</span>
            </div>
          ),
          labelText: `${labelCity}`,
          value: {
            city: value['city'],
            tz: value['timezone'],
          },
        })
      }
    })
    options.sort((a, b) => {
      if (a.labelText === b.labelText) return 0
      return a.labelText > b.labelText ? 1 : -1
    })
    setTzOptions(options)
  }, [])

  return (
    <TimezoneOptionsContext.Provider
      value={{
        tzOptions,
      }}
    >
      {children}
    </TimezoneOptionsContext.Provider>
  )
}

export { TimezoneOptionsContext, ProvideTimezoneOptionsContext }
